var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.toggle.documentFeeDetail
    ? _c(
        "div",
        { staticClass: "px-3 border-bottom" },
        [
          _c(
            "a-form-model-item",
            {
              attrs: {
                prop: "movingCostDocumentFee",
                "label-col": _vm.labelCol,
                label: _vm.$t("lbl_moving_cost_and_plp_charge")
              }
            },
            [
              _c("span", { staticClass: "mr-2" }, [
                _vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))
              ]),
              _c("a-input-number", {
                staticStyle: { width: "170px" },
                attrs: {
                  value: _vm.form.movingCostDocumentFee,
                  formatter: _vm.formatterNumber,
                  parser: _vm.reverseFormatNumber,
                  precision: _vm.DECIMAL_PLACES_QTY,
                  min: 0,
                  placeholder: _vm.$t("lbl_type_here"),
                  readonly: _vm.isUnbilled
                },
                on: {
                  change: function(e) {
                    return _vm.commitState(e, "movingCostDocumentFee")
                  }
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                prop: "documentFee",
                label: _vm.$t("lbl_document_fee"),
                "label-col": _vm.labelCol
              }
            },
            [
              _c("span", { staticClass: "mr-2" }, [
                _vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))
              ]),
              _c("a-input-number", {
                staticStyle: { width: "170px" },
                attrs: {
                  value: _vm.form.documentFee,
                  formatter: _vm.formatterNumber,
                  parser: _vm.reverseFormatNumber,
                  precision: _vm.DECIMAL_PLACES_QTY,
                  min: 0,
                  placeholder: _vm.$t("lbl_type_here"),
                  readonly: _vm.isUnbilled
                },
                on: {
                  change: function(e) {
                    return _vm.commitState(e, "documentFee")
                  }
                }
              })
            ],
            1
          ),
          _c("div", [
            _c(
              "div",
              {
                staticClass: "d-flex align-items-center justify-content-start"
              },
              [
                _c(
                  "label",
                  {
                    staticClass:
                      "form-label ant-col-5 ant-col ant-form-item-label ant-form-item-label-left"
                  },
                  [_vm._v(_vm._s(_vm.$t("lbl_tax")) + ":")]
                ),
                _c(
                  "a-form-model-item",
                  {
                    staticClass: "mr-3",
                    attrs: { prop: "taxDocumentFeeValue" }
                  },
                  [
                    _c("span", { staticClass: "ml-3" }, [
                      _vm._v(
                        _vm._s(_vm._f("currency")(_vm.form.taxDocumentFeeValue))
                      )
                    ])
                  ]
                ),
                _c(
                  "a-form-model-item",
                  {
                    staticClass: "mr-3",
                    attrs: { prop: "taxDocumentFeePercent" }
                  },
                  [
                    _c("a-input-number", {
                      staticStyle: { width: "170px" },
                      attrs: {
                        value: _vm.form.taxDocumentFeePercent,
                        formatter: _vm.formatterPercent,
                        precision: _vm.DECIMAL_PLACES_QTY,
                        min: 0,
                        max: 100,
                        placeholder: _vm.$t("lbl_type_here"),
                        readonly: _vm.isUnbilled
                      },
                      on: {
                        change: function(e) {
                          return _vm.commitState(e, "taxDocumentFeePercent")
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  {
                    staticClass: "mr-3",
                    attrs: {
                      prop: "taxDocumentFeeTotal",
                      "label-col": _vm.labelCol,
                      colon: false
                    }
                  },
                  [
                    _c("span", [_vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))]),
                    _c("span", { staticClass: "ml-3" }, [
                      _vm._v(
                        _vm._s(_vm._f("currency")(_vm.form.taxDocumentFeeTotal))
                      )
                    ])
                  ]
                )
              ],
              1
            )
          ]),
          _c(
            "a-form-model-item",
            {
              attrs: {
                prop: "stampDutyDocumentFee",
                "label-col": _vm.labelCol,
                label: _vm.$t("lbl_stamp_duty")
              }
            },
            [
              _c("span", { staticClass: "mr-2" }, [
                _vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))
              ]),
              _c("a-input-number", {
                staticStyle: { width: "170px" },
                attrs: {
                  value: _vm.form.stampDutyDocumentFee,
                  formatter: _vm.formatterNumber,
                  parser: _vm.reverseFormatNumber,
                  precision: _vm.DECIMAL_PLACES_QTY,
                  min: 0,
                  placeholder: _vm.$t("lbl_type_here"),
                  readonly: _vm.isUnbilled
                },
                on: {
                  change: function(e) {
                    return _vm.commitState(e, "stampDutyDocumentFee")
                  }
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                prop: "otherDocumentFee",
                "label-col": _vm.labelCol,
                label: _vm.$t("lbl_others")
              }
            },
            [
              _c("span", { staticClass: "mr-2" }, [
                _vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))
              ]),
              _c("a-input-number", {
                staticStyle: { width: "170px" },
                attrs: {
                  value: _vm.form.otherDocumentFee,
                  formatter: _vm.formatterNumber,
                  parser: _vm.reverseFormatNumber,
                  precision: _vm.DECIMAL_PLACES_QTY,
                  min: 0,
                  placeholder: _vm.$t("lbl_type_here"),
                  readonly: _vm.isUnbilled
                },
                on: {
                  change: function(e) {
                    return _vm.commitState(e, "otherDocumentFee")
                  }
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                prop: "totalDocumentFee",
                "label-col": _vm.labelCol,
                label: _vm.$t("lbl_total_document_fee")
              }
            },
            [
              _c("span", [_vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))]),
              _c("span", { staticClass: "ml-3" }, [
                _vm._v(_vm._s(_vm._f("currency")(_vm.form.totalDocumentFee)))
              ])
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }